@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(0, 0, 0, 0.080); */
  overflow-x: hidden;
  background-color: #f3f8f8;}
.Cell{
  border: 2px;
}

.scrollbar-hide {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* body::-webkit-scrollbar {
  display: none;
} */

* {
  font-family: "Open Sans", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: white
}
.scroll-visible::-webkit-scrollbar{
  display: block
}


@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0; /* Ensures no extra gaps */
}
react-datepicker__input-container input {
  width: 100% !important;
}
canvas {
  width: 100%;
  height: 100%;
  /* Adjust as needed */
}
.canvas {
  width: 100%;
  height: 100%;
  /* Adjust as needed */
}