
/* 
@media (max-width: 1536px) {
    .potential-download {
      display: none;
    }
  }

  @media (min-width: 1600px){
    .show-potential-download{
        visibility: hidden;
    }
  } */

  .date-picker-placeholder::placeholder {
    color: black; /* Set placeholder text color to black */
  }

  